import { useContext } from 'react';
import { ModalContext } from '../contexts/ModalContext';

export const useModalsGlobal = () => {
	const { modals, setModals } = useContext(ModalContext);

	const showModal = (type, options) => {
		setModals((modals) => [...modals, { type, zIndex: (options?.zIndex || 100) + modals?.length, options }]);
	};

	const hideModal = (index) => {
		setModals((modals) => modals?.filter((_, i) => i !== index));
	};

	return { modals, showModal, hideModal };
};
