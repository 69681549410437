import React from 'react';

import { ReactComponent as ProcuurSmallLogo } from '../../assets/svg/favicon.svg';

import { ReactComponent as CartPlus } from '../../assets/svg/24x24_bold/iconB-cart-plus.svg';

import { ReactComponent as TeamPlus } from '../../assets/svg/24x24_bold/iconB-team-plus.svg';
import { ReactComponent as PersonPortal } from '../../assets/svg/24x24_bold/iconB-person-to-portal.svg';
import { ReactComponent as Supplier } from '../../assets/svg/24x24_bold/iconB-supplier.svg';
import { ReactComponent as SupplierPlus } from '../../assets/svg/24x24_bold/iconB-supplier-plus.svg';
import { ReactComponent as CartBuy } from '../../assets/svg/24x24_bold/iconB-cart-buy.svg';
import { ReactComponent as Bell } from '../../assets/svg/24x24_bold/iconB-bell.svg';
import { ReactComponent as UserPlus } from '../../assets/svg/24x24_bold/iconB-user-plus.svg';

import { ReactComponent as ManageTeam } from '../../assets/svg/users-gear.svg';
import { ReactComponent as ArrowPrevLeft } from '../../assets/svg/navigation/iconR-previous-left.svg';
import { ReactComponent as ArrowNextRight } from '../../assets/svg/navigation/iconR-next-right.svg';
import { ReactComponent as SignOut } from '../../assets/svg/24x24_bold/iconB-sign-out.svg';
import { ReactComponent as SignIn } from '../../assets/svg/24x24_bold/iconB-sign-in.svg';
import { ReactComponent as MultiSignOut } from '../../assets/svg/24x24_bold/iconB-multi-sign-out.svg';

import { ReactComponent as TopnavMenu } from '../../assets/svg/24x24_bold/iconB-menu.svg';
import { ReactComponent as TopnavClose } from '../../assets/svg/24x24_bold/iconB-close.svg';
import { ReactComponent as TopnavUserCircle } from '../../assets/svg/24x24_bold/iconB-user-circle.svg';
import { ReactComponent as TopnavTeamCircle } from '../../assets/svg/24x24_bold/iconB-team-circle.svg';
import { ReactComponent as TopnavBell } from '../../assets/svg/24x24_bold/iconB-bell.svg';
import { ReactComponent as TopnavRocket } from '../../assets/svg/24x24_bold/iconB-rocket.svg';

import { ReactComponent as CartSell } from '../../assets/svg/24x24_bold/iconB-cart-sell.svg';
import { ReactComponent as Chart } from '../../assets/svg/navigation/nav-sidebar/iconR-chart.svg';
import { ReactComponent as Dashboard } from '../../assets/svg/navigation/nav-sidebar/iconR-dashboard.svg';
import { ReactComponent as Legal } from '../../assets/svg/24x24_bold/iconB-scales.svg';
import { ReactComponent as Support } from '../../assets/svg/navigation/nav-sidebar/iconR-support.svg';

import { ReactComponent as Chat } from '../../assets/svg/24x24_bold/iconB-chat.svg';
import { ReactComponent as BoxesStacked } from '../../assets/svg/24x24_bold/iconB-boxes-stacked.svg';
import { ReactComponent as Customer } from '../../assets/svg/24x24_bold/iconB-customer.svg';
import { ReactComponent as Shop } from '../../assets/svg/24x24_bold/iconB-shop.svg';
import { ReactComponent as TruckRight } from '../../assets/svg/24x24_bold/iconB-truck-right.svg';
import { ReactComponent as Analytics } from '../../assets/svg/24x24_bold/iconB-analytics.svg';
import { ReactComponent as App } from '../../assets/svg/24x24_bold/iconB-app.svg';
import { ReactComponent as Scales } from '../../assets/svg/24x24_bold/iconB-scales.svg';
import { ReactComponent as Wrench } from '../../assets/svg/24x24_bold/iconB-wrench.svg';
import { ReactComponent as ClipboardPlus } from '../../assets/svg/24x24_bold/iconB-clipboard-plus.svg';

import { ReactComponent as ToggleOn } from '../../assets/svg/iconR-toggle-on.svg';
import { ReactComponent as ToggleOff } from '../../assets/svg/iconR-toggle-off.svg';
import { ReactComponent as At } from '../../assets/svg/24x24_bold/iconB-at-sign.svg';
import { ReactComponent as Globe } from '../../assets/svg/24x24_bold/iconB-globe.svg';
import { ReactComponent as Plus } from '../../assets/svg/24x24_bold/iconB-plus.svg';

import { ReactComponent as Pen } from '../../assets/svg/24x24_bold/iconB-pen.svg';
import { ReactComponent as PhoneOut } from '../../assets/svg/24x24_bold/iconB-phone-out.svg';
import { ReactComponent as Question } from '../../assets/svg/24x24_bold/iconB-question.svg';
import { ReactComponent as Bookmark } from '../../assets/svg/24x24_bold/iconB-bookmark.svg';
import { ReactComponent as TruckLeft } from '../../assets/svg/24x24_bold/iconB-truck-left.svg';
import { ReactComponent as Bill } from '../../assets/svg/24x24_bold/iconB-bill-GBP.svg';
import { ReactComponent as ListOrdered } from '../../assets/svg/24x24_bold/iconB-list-ordered.svg';
import { ReactComponent as File } from '../../assets/svg/24x24_bold/iconB-file.svg';
import { ReactComponent as Tracking } from '../../assets/svg/24x24_bold/iconB-tracking.svg';
import { ReactComponent as Progress } from '../../assets/svg/24x24_bold/iconB-progress.svg';
import { ReactComponent as ClipboardArrowDown } from '../../assets/svg/24x24_bold/iconB-clipboard-arrow-down.svg';
import { ReactComponent as ClipboardArrowsClockwise } from '../../assets/svg/24x24_bold/iconB-clipboard-arrows-clockwise.svg';
import { ReactComponent as ThreeDots } from '../../assets/svg/24x24_bold/iconB-3dots-vertical.svg';
import { ReactComponent as ThreeDotsHorizontal } from '../../assets/svg/24x24_bold/iconB-3dots-horizontal.svg';
import { ReactComponent as TeamCheck } from '../../assets/svg/24x24_bold/iconB-team-check.svg';
import { ReactComponent as Phone } from '../../assets/svg/24x24_bold/iconB-phone.svg';
import { ReactComponent as Slack } from '../../assets/svg/24x24_bold/_brands/icon-slack.svg';
import { ReactComponent as Envelope } from '../../assets/svg/24x24_bold/iconB-envelope.svg';
import { ReactComponent as Archive } from '../../assets/svg/24x24_bold/iconB-box-archive.svg';
import { ReactComponent as Share } from '../../assets/svg/24x24_bold/iconB-share.svg';
import { ReactComponent as Floppy } from '../../assets/svg/24x24_bold/iconB-floppy.svg';
import { ReactComponent as Trash } from '../../assets/svg/24x24_bold/iconB-trash.svg';
import { ReactComponent as Close } from '../../assets/svg/24x24_bold/iconB-close.svg';
import { ReactComponent as Search } from '../../assets/svg/24x24_bold/iconB-search.svg';
import { ReactComponent as Gear } from '../../assets/svg/24x24_bold/iconB-gear.svg';
import { ReactComponent as AnnotationDots } from '../../assets/svg/24x24_bold/iconB-annotation-dots.svg';
import { ReactComponent as UserCircle } from '../../assets/svg/24x24_bold/iconB-user-circle.svg';
import { ReactComponent as TeamCircle } from '../../assets/svg/24x24_bold/iconB-team-circle.svg';
import { ReactComponent as FileLines } from '../../assets/svg/24x24_bold/iconB-file-2lines.svg';
import { ReactComponent as SectionSign } from '../../assets/svg/24x24_bold/iconB-section-sign.svg';
import { ReactComponent as ChevronRight } from '../../assets/svg/24x24_bold/iconB-chevron-right.svg';
import { ReactComponent as ChevronLeft } from '../../assets/svg/24x24_bold/iconB-chevron-left.svg';
import { ReactComponent as ArrowNarrowLeft } from '../../assets/svg/24x24_bold/iconB-arrow-narrow-left.svg';
import { ReactComponent as ArrowNarrowRight } from '../../assets/svg/24x24_bold/iconB-arrow-narrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/svg/24x24_bold/iconB-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/24x24_bold/iconB-arrow-right.svg';
import { ReactComponent as Sliders } from '../../assets/svg/24x24_bold/iconB-sliders.svg';
import { ReactComponent as Check } from '../../assets/svg/24x24_bold/iconB-check.svg';
import { ReactComponent as CheckSquareBroken } from '../../assets/svg/24x24_bold/check-square-broken.svg';
import { ReactComponent as DownloadCloud } from '../../assets/svg/download-cloud.svg';
import { ReactComponent as UploadCloud } from '../../assets/svg/24x24_bold/iconB-upload-cloud.svg';
import { ReactComponent as Upload } from '../../assets/svg/24x24_bold/iconB-upload.svg';
import { ReactComponent as ClipboardInfo } from '../../assets/svg/24x24_bold/iconB-clipboard-info.svg';
import { ReactComponent as ListEdit } from '../../assets/svg/24x24_bold/iconB-list-edit.svg';
import { ReactComponent as MessageChatSquare } from '../../assets/svg/24x24_bold/iconB-message-chat-square.svg';
import { ReactComponent as Command } from '../../assets/svg/24x24_bold/iconB-command.svg';
import { ReactComponent as PlayCircle } from '../../assets/svg/24x24_bold/iconB-play-circle.svg';
import { ReactComponent as CheckCircle } from '../../assets/svg/24x24_bold/iconB-check-circle.svg';
import { ReactComponent as ArrowsIn } from '../../assets/svg/24x24_bold/iconB-arrows-in.svg';
import { ReactComponent as ArrowsOut } from '../../assets/svg/24x24_bold/iconB-arrows-out.svg';
import { ReactComponent as Flag } from '../../assets/svg/24x24_bold/iconB-flag.svg';
import { ReactComponent as FlagSolid } from '../../assets/svg/24x24_bold/iconS-flag.svg';
import { ReactComponent as Kanban } from '../../assets/svg/24x24_bold/iconB-kanban.svg';
import { ReactComponent as InvoiceInfo } from '../../assets/svg/24x24_bold/iconB-invoice-info.svg';
import { ReactComponent as InfoCircle } from '../../assets/svg/24x24_bold/iconB-info-circle.svg';
import { ReactComponent as HomeSimple } from '../../assets/svg/24x24_bold/iconB-home-simple.svg';
import { ReactComponent as QuestionCircle } from '../../assets/svg/24x24_bold/iconB-question-circle.svg';
import { ReactComponent as Handshake } from '../../assets/svg/24x24_bold/iconB-handshake.svg';
import { ReactComponent as Tag } from '../../assets/svg/24x24_bold/iconB-tag.svg';
import { ReactComponent as User } from '../../assets/svg/24x24_bold/iconB-user.svg';
import { ReactComponent as ClipboardCheck } from '../../assets/svg/24x24_bold/iconB-clipboard-check.svg';
import { ReactComponent as Cart } from '../../assets/svg/24x24_bold/iconB-cart.svg';
import { ReactComponent as MenuMain } from '../../assets/svg/24x24_bold/iconB-menu-main.svg';
import { ReactComponent as PipeDivider } from '../../assets/svg/24x24_bold/iconB-pipe-divider.svg';
import { ReactComponent as InvoiceGBP } from '../../assets/svg/24x24_bold/iconB-invoice-GPB.svg';
import { ReactComponent as Send } from '../../assets/svg/24x24_bold/iconB-send.svg';
import { ReactComponent as CoinsStacked } from '../../assets/svg/24x24_bold/iconB-coins-stacked.svg';
import { ReactComponent as ChevronDown } from '../../assets/svg/24x24_bold/iconB-chevron-down.svg';
import { ReactComponent as CheckDouble } from '../../assets/svg/24x24_bold/iconB-check-double.svg';
import { ReactComponent as File2Lines } from '../../assets/svg/24x24_bold/iconB-file-2lines.svg';
import { ReactComponent as FileInfo } from '../../assets/svg/24x24_bold/iconB-file-info.svg';
import { ReactComponent as ArrowNarrowUpRight } from '../../assets/svg/24x24_bold/iconB-arrow-narrow-up-right.svg';
import { ReactComponent as PenFrame } from '../../assets/svg/24x24_bold/iconB-pen-frame.svg';
import { ReactComponent as Target } from '../../assets/svg/24x24_bold/iconB-target.svg';
import { ReactComponent as ClockStopwatch } from '../../assets/svg/24x24_bold/iconB-clock-stopwatch.svg';
import { ReactComponent as Calendar } from '../../assets/svg/24x24_bold/iconB-calendar.svg';
import { ReactComponent as Download } from '../../assets/svg/24x24_bold/iconB-download.svg';
import { ReactComponent as CloseX } from '../../assets/svg/24x24_bold/iconB-x-close.svg';
import { ReactComponent as UserCog } from '../../assets/svg/24x24_bold/iconB-user-cog.svg';
import { ReactComponent as Lock } from '../../assets/svg/24x24_bold/iconB-lock.svg';
import { ReactComponent as UserCancel } from '../../assets/svg/24x24_bold/iconB-user-cancel.svg';
import { ReactComponent as MessageChatSquareGray } from '../../assets/svg/24x24_bold/iconB-message-chat-square-gray.svg';

import { ReactComponent as ToolLineThin } from '../../assets/svg/docket/drawingTools_24x24px/icon-tool-line-thin.svg';
import { ReactComponent as ToolLineMedium } from '../../assets/svg/docket/drawingTools_24x24px/icon-tool-line-medium.svg';
import { ReactComponent as ToolLineThick } from '../../assets/svg/docket/drawingTools_24x24px/icon-tool-line-thick.svg';
import { ReactComponent as ToolScribbleThin } from '../../assets/svg/docket/drawingTools_24x24px/icon-tool-scribble-thin.svg';
import { ReactComponent as ToolScribbleMedium } from '../../assets/svg/docket/drawingTools_24x24px/icon-tool-scribble-medium.svg';
import { ReactComponent as ToolScribbleThick } from '../../assets/svg/docket/drawingTools_24x24px/icon-tool-scribble-thick.svg';
import { ReactComponent as FlipBackwardBold } from '../../assets/svg/docket/drawingTools_24x24px/iconB-flip-backward.svg';
import { ReactComponent as FlipForwardBold } from '../../assets/svg/docket/drawingTools_24x24px/iconB-flip-forward.svg';
import { ReactComponent as ReverseLeft } from '../../assets/svg/docket/drawingTools_24x24px/iconB-reverse-left.svg';
import { ReactComponent as ReverseRight } from '../../assets/svg/docket/drawingTools_24x24px/iconB-reverse-right.svg';
import { ReactComponent as Broom } from '../../assets/svg/docket/drawingTools_24x24px/iconS-broom.svg';
import { ReactComponent as Eraser } from '../../assets/svg/docket/drawingTools_24x24px/iconS-eraser.svg';
import { ReactComponent as FileExport } from '../../assets/svg/docket/drawingTools_24x24px/iconS-file-export.svg';
import { ReactComponent as FlipBackwardSlim } from '../../assets/svg/docket/drawingTools_24x24px/iconS-flip-backward.svg';
import { ReactComponent as FlipForwardSlim } from '../../assets/svg/docket/drawingTools_24x24px/iconS-flip-forward.svg';
import { ReactComponent as TextT } from '../../assets/svg/docket/drawingTools_24x24px/iconS-T.svg';
import { ReactComponent as ToolLinePen } from '../../assets/svg/docket/drawingTools_24x24px/iconS-tool-line-pen.svg';
import { ReactComponent as ToolScribblePen } from '../../assets/svg/docket/drawingTools_24x24px/iconS-tool-scribble-pen.svg';
import { ReactComponent as ToolScribbleThickSlim } from '../../assets/svg/docket/drawingTools_24x24px/iconS-tool-scribble-thick.svg';

import { ReactComponent as FileThin } from '../../assets/svg/iconS-file.svg';

import { ReactComponent as TeamGear } from '../../assets/svg/24x24_bold/iconB-team-gear.svg';
import { ReactComponent as MapPin } from '../../assets/svg/24x24_bold/iconB-map-pin.svg';
import { ReactComponent as BillGBP } from '../../assets/svg/24x24_bold/iconB-bill-GBP.svg';

import { ReactComponent as SupplierTrusted } from '../../assets/svg/24x24_bold/iconS-supplier-trusted.svg';

import { ReactComponent as Learning1 } from '../../assets/SVG_Learning-Zone/learning-01.svg';
import { ReactComponent as Learning2 } from '../../assets/SVG_Learning-Zone/learning-02.svg';
import { ReactComponent as Learning3 } from '../../assets/SVG_Learning-Zone/learning-03.svg';
import { ReactComponent as Learning4 } from '../../assets/SVG_Learning-Zone/learning-04.svg';

import { ReactComponent as Verified } from '../../assets/svg/ranking/iconS-medal-badge-check.svg';
import { ReactComponent as VerifiedSmall } from '../../assets/svg/rating/iconS-badge-check-XL.svg';
import { ReactComponent as TrustedHollow } from '../../assets/svg/rating/iconR-supplier-XL.svg';

import { ReactComponent as Print } from '../../assets/svg/24x24_bold/iconB-print.svg';

import { ReactComponent as ProductPlaceholder } from '../../assets/svg/product-img-blank.svg';

import { ReactComponent as ProgressAccepted } from '../../assets/svg/progress-tracker/30x30/iconR-progress-accepted.svg';
import { ReactComponent as ProgressCompleted } from '../../assets/svg/progress-tracker/30x30/iconR-progress-completed.svg';
import { ReactComponent as ProgressDelivered } from '../../assets/svg/progress-tracker/30x30/iconR-progress-delivered.svg';
import { ReactComponent as ProgressDispatched } from '../../assets/svg/progress-tracker/30x30/iconR-progress-dispatched.svg';
import { ReactComponent as ProgressDisputed } from '../../assets/svg/progress-tracker/30x30/iconR-progress-disputed.svg';
import { ReactComponent as ProgressDraft } from '../../assets/svg/progress-tracker/30x30/iconR-progress-draft.svg';
import { ReactComponent as ProgressPending } from '../../assets/svg/progress-tracker/30x30/iconR-progress-pending.svg';
import { ReactComponent as ProgressProcessing } from '../../assets/svg/progress-tracker/30x30/iconR-progress-processing.svg';
import { ReactComponent as ProgressRejected } from '../../assets/svg/progress-tracker/30x30/iconR-progress-rejected.svg';
import { ReactComponent as ProgressVoid } from '../../assets/svg/progress-tracker/30x30/iconR-progress-void.svg';
import { ReactComponent as ProgressHold } from '../../assets/svg/progress-tracker/iconB-progress-hold.svg';

import { ReactComponent as ProgressAcceptedInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-accepted-inverse.svg';
import { ReactComponent as ProgressCompletedInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-completed-inverse.svg';
import { ReactComponent as ProgressDeliveredInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-delivered-inverse.svg';
import { ReactComponent as ProgressDispatchedInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-dispatched-inverse.svg';
import { ReactComponent as ProgressDisputedInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-disputed-inverse.svg';
import { ReactComponent as ProgressDraftInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-draft-inverse.svg';
import { ReactComponent as ProgressPendingInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-pending-inverse.svg';
import { ReactComponent as ProgressProcessingInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-processing-inverse.svg';
import { ReactComponent as ProgressRejectedInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-rejected-inverse.svg';
import { ReactComponent as ProgressVoidInverse } from '../../assets/svg/progress-tracker/30x30/inverted/iconR-progress-void-inverse.svg';

import { ReactComponent as Team } from '../../assets/svg/24x24_bold/iconB-team.svg';
import { ReactComponent as Chain } from '../../assets/svg/24x24_bold/iconB-chain.svg';

import { ReactComponent as DocketH } from '../../assets/svg/docket/build_24x24/icon-H.svg';
import { ReactComponent as DocketTemplate } from '../../assets/svg/docket/build_24x24/iconB-template.svg';
import { ReactComponent as DocketVectorPolygon } from '../../assets/svg/docket/build_24x24/iconB-vector-polygon.svg';
import { ReactComponent as DocketWidgets } from '../../assets/svg/docket/build_24x24/iconB-widgets.svg';
import { ReactComponent as DocketNumbers } from '../../assets/svg/docket/build_24x24/iconR-numbers.svg';
import { ReactComponent as DocketRichtext } from '../../assets/svg/docket/build_24x24/iconR-richtext.svg';
import { ReactComponent as DocketText } from '../../assets/svg/docket/build_24x24/iconR-textfield.svg';
import { ReactComponent as DocketTextarea } from '../../assets/svg/docket/build_24x24/iconR-textarea.svg';
import { ReactComponent as DocketCalendar } from '../../assets/svg/docket/build_24x24/iconS-calendar.svg';
import { ReactComponent as DocketCheckbox } from '../../assets/svg/docket/build_24x24/iconS-checkbox.svg';
import { ReactComponent as DocketTriDown } from '../../assets/svg/docket/build_24x24/iconS-circle-triangle-down.svg';
import { ReactComponent as DocketCloudUp } from '../../assets/svg/docket/build_24x24/iconS-cloud-up.svg';
import { ReactComponent as DocketEmail } from '../../assets/svg/docket/build_24x24/iconS-email.svg';
import { ReactComponent as DocketFile } from '../../assets/svg/docket/build_24x24/iconS-file.svg';
import { ReactComponent as DocketDotsHor } from '../../assets/svg/docket/build_24x24/iconS-grip-dots-horizontal.svg';
import { ReactComponent as DocketDotsVer } from '../../assets/svg/docket/build_24x24/iconS-grip-dots-vertical.svg';
import { ReactComponent as DocketGroup } from '../../assets/svg/docket/build_24x24/iconS-group.svg';
import { ReactComponent as DocketImage } from '../../assets/svg/docket/build_24x24/iconS-image.svg';
import { ReactComponent as DocketPhone } from '../../assets/svg/docket/build_24x24/iconS-phone.svg';
import { ReactComponent as DocketRadio } from '../../assets/svg/docket/build_24x24/iconS-radio-btn.svg';
import { ReactComponent as DocketScribble } from '../../assets/svg/docket/build_24x24/iconS-scribble.svg';
import { ReactComponent as DocketSwitch } from '../../assets/svg/docket/build_24x24/iconS-switch.svg';
import { ReactComponent as DocketT } from '../../assets/svg/docket/build_24x24/iconS-T.svg';
import { ReactComponent as Library } from '../../assets/svg/docket/build_24x24/iconB-library.svg';

import { ReactComponent as Crown } from '../../assets/svg/docket/templates_40x40/iconS-tooth-crown.svg';
import { ReactComponent as Bridge } from '../../assets/svg/docket/templates_40x40/iconS-tooth-bridge.svg';
import { ReactComponent as Denture } from '../../assets/svg/docket/templates_40x40/iconS-teeth-open.svg';
import { ReactComponent as Implant } from '../../assets/svg/docket/templates_40x40/iconS-tooth-implant.svg';
import { ReactComponent as ImplantedBridge } from '../../assets/svg/docket/templates_40x40/iconS-tooth-bridge-implant.svg';
import { ReactComponent as CantileverBridge } from '../../assets/svg/docket/templates_40x40/iconS-tooth-bridge-cantilever.svg';
import { ReactComponent as MarylandBridge } from '../../assets/svg/docket/templates_40x40/iconS-tooth-bridge-maryland.svg';
import { ReactComponent as SkeletonAnalytics } from '../../assets/svg/skeleton loader images/home-analytics.svg';

function handleKeyDown(e, onclick) {
	if (e.key == 'Enter' && onclick) {
		onclick();
	}
}

// * CUSTOM ICONS *

// TEMP
export const TestIcon = (props) => {
	return (
		<Test
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProcuurSmallLogoIcon = (props) => {
	return (
		<ProcuurSmallLogo
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Docket Creator page
export const DocketHIcon = (props) => {
	return (
		<DocketH
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketTemplateIcon = (props) => {
	return (
		<DocketTemplate
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketVectorPolygonIcon = (props) => {
	return (
		<DocketVectorPolygon
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketWidgetsIcon = (props) => {
	return (
		<DocketWidgets
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketNumbersIcon = (props) => {
	return (
		<DocketNumbers
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketRichtextIcon = (props) => {
	return (
		<DocketRichtext
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketTextIcon = (props) => {
	return (
		<DocketText
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketTextareaIcon = (props) => {
	return (
		<DocketTextarea
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketCalendarIcon = (props) => {
	return (
		<DocketCalendar
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketCheckboxIcon = (props) => {
	return (
		<DocketCheckbox
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketTriDownIcon = (props) => {
	return (
		<DocketTriDown
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketCloudUpIcon = (props) => {
	return (
		<DocketCloudUp
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketEmailIcon = (props) => {
	return (
		<DocketEmail
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketFileIcon = (props) => {
	return (
		<DocketFile
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketDotsHorIcon = (props) => {
	return (
		<DocketDotsHor
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketDotsVerIcon = (props) => {
	return (
		<DocketDotsVer
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketGroupIcon = (props) => {
	return (
		<DocketGroup
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketImageIcon = (props) => {
	return (
		<DocketImage
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketPhoneIcon = (props) => {
	return (
		<DocketPhone
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketRadioIcon = (props) => {
	return (
		<DocketRadio
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketScribbleIcon = (props) => {
	return (
		<DocketScribble
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketSwitchIcon = (props) => {
	return (
		<DocketSwitch
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DocketTIcon = (props) => {
	return (
		<DocketT
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const LibraryIcon = (props) => {
	return (
		<Library
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TrashDiscIcon = (props) => {
	return (
		<TrashDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CloseDiscIcon = (props) => {
	return (
		<CloseDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Order page
export const BookmarkIcon = (props) => {
	return (
		<Bookmark
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TruckLeftIcon = (props) => {
	return (
		<TruckLeft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BillIcon = (props) => {
	return (
		<Bill
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ListOrderedIcon = (props) => {
	return (
		<ListOrdered
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FileIcon = (props) => {
	return (
		<File
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TrackingIcon = (props) => {
	return (
		<Tracking
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PlusDiscIcon = (props) => {
	return (
		<PlusDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ShareDiscIcon = (props) => {
	return (
		<ShareDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ShopDiscIcon = (props) => {
	return (
		<ShopDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressIcon = (props) => {
	return (
		<Progress
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const MapPinIcon = (props) => {
	return (
		<MapPin
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BillGBPIcon = (props) => {
	return (
		<BillGBP
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TeamIcon = (props) => {
	return (
		<Team
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChainIcon = (props) => {
	return (
		<Chain
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BoxesStackedIcon = (props) => {
	return (
		<BoxesStacked
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CustomerIcon = (props) => {
	return (
		<Customer
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ShopIcon = (props) => {
	return (
		<Shop
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TruckRightIcon = (props) => {
	return (
		<TruckRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const AnalyticsIcon = (props) => {
	return (
		<Analytics
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const AppIcon = (props) => {
	return (
		<App
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ScalesIcon = (props) => {
	return (
		<Scales
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const WrenchIcon = (props) => {
	return (
		<Wrench
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ClipboardPlusIcon = (props) => {
	return (
		<ClipboardPlus
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UserPlusIcon = (props) => {
	return (
		<UserPlus
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FloppyIcon = (props) => {
	return (
		<Floppy
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TrashIcon = (props) => {
	return (
		<Trash
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CloseIcon = (props) => {
	return (
		<Close
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SearchIcon = (props) => {
	return (
		<Search
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const GearIcon = (props) => {
	return (
		<Gear
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const AnnotationDotsIcon = (props) => {
	return (
		<AnnotationDots
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UserCircleIcon = (props) => {
	return (
		<UserCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TeamCircleIcon = (props) => {
	return (
		<TeamCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// System
export const PenIcon = (props) => {
	return (
		<Pen
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ManageTeamIcon = (props) => {
	return (
		<ManageTeam
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowPrevLeftIcon = (props) => {
	return (
		<ArrowPrevLeft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowNextRightIcon = (props) => {
	return (
		<ArrowNextRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SigninIcon = (props) => {
	return (
		<SignIn
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SignOutIcon = (props) => {
	return (
		<SignOut
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const MultiSignOutIcon = (props) => {
	return (
		<MultiSignOut
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const GotoDiscBlack40Icon = (props) => {
	return (
		<GotoDiscBlack40
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProductPlaceholderIcon = (props) => {
	return (
		<ProductPlaceholder
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ClipboardArrowDownIcon = (props) => {
	return (
		<ClipboardArrowDown
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ClipboardArrowsClockwiseIcon = (props) => {
	return (
		<ClipboardArrowsClockwise
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ThreeDotsIcon = (props) => {
	return (
		<ThreeDots
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ThreeDotsHorizontalIcon = (props) => {
	return (
		<ThreeDotsHorizontal
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TeamCheckIcon = (props) => {
	return (
		<TeamCheck
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArchiveIcon = (props) => {
	return (
		<Archive
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ShareIcon = (props) => {
	return (
		<Share
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FileLinesIcon = (props) => {
	return (
		<FileLines
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SectionSignIcon = (props) => {
	return (
		<SectionSign
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChevronRightIcon = (props) => {
	return (
		<ChevronRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChevronLeftIcon = (props) => {
	return (
		<ChevronLeft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowNarrowLeftIcon = (props) => {
	return (
		<ArrowNarrowLeft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowNarrowRightIcon = (props) => {
	return (
		<ArrowNarrowRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowLeftIcon = (props) => {
	return (
		<ArrowLeft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowRightIcon = (props) => {
	return (
		<ArrowRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SlidersIcon = (props) => {
	return (
		<Sliders
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CheckIcon = (props) => {
	return (
		<Check
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CheckSquareBrokenIcon = (props) => {
	return (
		<CheckSquareBroken
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FileThinIcon = (props) => {
	return (
		<FileThin
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DownloadCloudIcon = (props) => {
	return (
		<DownloadCloud
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UploadCloudIcon = (props) => {
	return (
		<UploadCloud
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UploadIcon = (props) => {
	return (
		<Upload
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ClipboardInfoIcon = (props) => {
	return (
		<ClipboardInfo
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ListEditIcon = (props) => {
	return (
		<ListEdit
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const MessageChatSquareIcon = (props) => {
	return (
		<MessageChatSquare
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CommandIcon = (props) => {
	return (
		<Command
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PlayCircleIcon = (props) => {
	return (
		<PlayCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CheckCircleIcon = (props) => {
	return (
		<CheckCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowsInIcon = (props) => {
	return (
		<ArrowsIn
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowsOutIcon = (props) => {
	return (
		<ArrowsOut
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FlagIcon = (props) => {
	return (
		<Flag
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FlagSolidIcon = (props) => {
	return (
		<FlagSolid
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const KanbanIcon = (props) => {
	return (
		<Kanban
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const InvoiceInfoIcon = (props) => {
	return (
		<InvoiceInfo
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const InfoCircleIcon = (props) => {
	return (
		<InfoCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const HomeSimpleIcon = (props) => {
	return (
		<HomeSimple
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const QuestionCircleIcon = (props) => {
	return (
		<QuestionCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const HandshakeIcon = (props) => {
	return (
		<Handshake
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TagIcon = (props) => {
	return (
		<Tag
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UserIcon = (props) => {
	return (
		<User
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ClipboardCheckIcon = (props) => {
	return (
		<ClipboardCheck
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CartIcon = (props) => {
	return (
		<Cart
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const MenuMainIcon = (props) => {
	return (
		<MenuMain
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PipeDividerIcon = (props) => {
	return (
		<PipeDivider
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const InvoiceGBPIcon = (props) => {
	return (
		<InvoiceGBP
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SendIcon = (props) => {
	return (
		<Send
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CoinsStackedIcon = (props) => {
	return (
		<CoinsStacked
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChevronDownIcon = (props) => {
	return (
		<ChevronDown
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CheckDoubleIcon = (props) => {
	return (
		<CheckDouble
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const File2LinesIcon = (props) => {
	return (
		<File2Lines
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FileInfoIcon = (props) => {
	return (
		<FileInfo
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArrowNarrowUpRightIcon = (props) => {
	return (
		<ArrowNarrowUpRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PenFrameIcon = (props) => {
	return (
		<PenFrame
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TargetIcon = (props) => {
	return (
		<Target
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ClockStopwatchIcon = (props) => {
	return (
		<ClockStopwatch
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CalendarIcon = (props) => {
	return (
		<Calendar
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DownloadIcon = (props) => {
	return (
		<Download
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CloseXIcon = (props) => {
	return (
		<CloseX
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UserCogIcon = (props) => {
	return (
		<UserCog
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const LockIcon = (props) => {
	return (
		<Lock
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UserCancelIcon = (props) => {
	return (
		<UserCancel
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const MessageChatSquareGrayIcon = (props) => {
	return (
		<MessageChatSquareGray
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Team Dash Icons
export const TeamGearIcon = (props) => {
	return (
		<TeamGear
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Nav-header
export const TopnavUserCircleIcon = (props) => {
	return (
		<TopnavUserCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TopnavTeamCircleIcon = (props) => {
	return (
		<TopnavTeamCircle
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TopnavMenuIcon = (props) => {
	return (
		<TopnavMenu
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TopnavCloseIcon = (props) => {
	return (
		<TopnavClose
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TopnavBellIcon = (props) => {
	return (
		<TopnavBell
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TopnavRocketIcon = (props) => {
	return (
		<TopnavRocket
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Nav-topbar
export const TeamPlusIcon = (props) => {
	return (
		<TeamPlus
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PersonPortalIcon = (props) => {
	return (
		<PersonPortal
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SupplierIcon = (props) => {
	return (
		<Supplier
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SupplierPlusIcon = (props) => {
	return (
		<SupplierPlus
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Nav-sidebar
export const CartBuyIcon = (props) => {
	return (
		<CartBuy
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CartSellIcon = (props) => {
	return (
		<CartSell
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChartIcon = (props) => {
	return (
		<Chart
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DashboardIcon = (props) => {
	return (
		<Dashboard
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const LegalIcon = (props) => {
	return (
		<Legal
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SupportIcon = (props) => {
	return (
		<Support
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Supplier card
export const CartPlusIcon = (props) => {
	return (
		<CartPlus
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChatIcon = (props) => {
	return (
		<Chat
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Supplier listing
export const PhoneOutIcon = (props) => {
	return (
		<PhoneOut
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Team management
export const AppDiscIcon = (props) => {
	return (
		<AppDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BillDiscIcon = (props) => {
	return (
		<BillDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ChartDiscIcon = (props) => {
	return (
		<ChartDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const LegalDiscIcon = (props) => {
	return (
		<LegalDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProductDiscIcon = (props) => {
	return (
		<ProductDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToggleOnIcon = (props) => {
	return (
		<ToggleOn
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToggleOffIcon = (props) => {
	return (
		<ToggleOff
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ArchiveDiscIcon = (props) => {
	return (
		<ArchiveDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const UserPlusDiscIcon = (props) => {
	return (
		<UserPlusDisc
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const AtIcon = (props) => {
	return (
		<At
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const GlobeIcon = (props) => {
	return (
		<Globe
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PhoneIcon = (props) => {
	return (
		<Phone
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const SlackIcon = (props) => {
	return (
		<Slack
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const EnvelopeIcon = (props) => {
	return (
		<Envelope
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BellIcon = (props) => {
	return (
		<Bell
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const PlusIcon = (props) => {
	return (
		<Plus
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Product-related
export const SupplierTrustedIcon = (props) => {
	return (
		<SupplierTrusted
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// SVG Learning Zone
export const Learning1Icon = (props) => {
	return (
		<Learning1
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const Learning2Icon = (props) => {
	return (
		<Learning2
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const Learning3Icon = (props) => {
	return (
		<Learning3
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const Learning4Icon = (props) => {
	return (
		<Learning4
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const QuestionIcon = (props) => {
	return (
		<Question
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Ranking
export const RankCommBlackIcon = (props) => {
	return (
		<RankCommBlack
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const RankCommWhiteIcon = (props) => {
	return (
		<RankCommWhite
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const VerifiedIcon = (props) => {
	return (
		<Verified
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const VerifiedSmallIcon = (props) => {
	return (
		<VerifiedSmall
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TrustedHollowIcon = (props) => {
	return (
		<TrustedHollow
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Buttons
export const PrintIcon = (props) => {
	return (
		<Print
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Progress Tracking
export const ProgressAcceptedIcon = (props) => {
	return (
		<ProgressAccepted
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressCompletedIcon = (props) => {
	return (
		<ProgressCompleted
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDeliveredIcon = (props) => {
	return (
		<ProgressDelivered
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDispatchedIcon = (props) => {
	return (
		<ProgressDispatched
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDisputedIcon = (props) => {
	return (
		<ProgressDisputed
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDraftIcon = (props) => {
	return (
		<ProgressDraft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressPendingIcon = (props) => {
	return (
		<ProgressPending
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressProcessingIcon = (props) => {
	return (
		<ProgressProcessing
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressRejectedIcon = (props) => {
	return (
		<ProgressRejected
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressVoidIcon = (props) => {
	return (
		<ProgressVoid
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressHoldIcon = (props) => {
	return (
		<ProgressHold
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

//Tracking INVERTED
export const ProgressAcceptedInverseIcon = (props) => {
	return (
		<ProgressAcceptedInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressCompletedInverseIcon = (props) => {
	return (
		<ProgressCompletedInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDeliveredInverseIcon = (props) => {
	return (
		<ProgressDeliveredInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDispatchedInverseIcon = (props) => {
	return (
		<ProgressDispatchedInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDisputedInverseIcon = (props) => {
	return (
		<ProgressDisputedInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressDraftInverseIcon = (props) => {
	return (
		<ProgressDraftInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressPendingInverseIcon = (props) => {
	return (
		<ProgressPendingInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressProcessingInverseIcon = (props) => {
	return (
		<ProgressProcessingInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressRejectedInverseIcon = (props) => {
	return (
		<ProgressRejectedInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ProgressVoidInverseIcon = (props) => {
	return (
		<ProgressVoidInverse
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Docket icons
export const CrownIcon = (props) => {
	return (
		<Crown
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BridgeIcon = (props) => {
	return (
		<Bridge
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const DentureIcon = (props) => {
	return (
		<Denture
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ImplantIcon = (props) => {
	return (
		<Implant
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ImplantedBridgeIcon = (props) => {
	return (
		<ImplantedBridge
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const CantileverBridgeIcon = (props) => {
	return (
		<CantileverBridge
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const MarylandBridgeIcon = (props) => {
	return (
		<MarylandBridge
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Skeleton load
export const SkeletonAnalyticsIcon = (props) => {
	return (
		<SkeletonAnalytics
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// Drawing tool
export const ToolLineThinIcon = (props) => {
	return (
		<ToolLineThin
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolLineMediumIcon = (props) => {
	return (
		<ToolLineMedium
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolLineThickIcon = (props) => {
	return (
		<ToolLineThick
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolScribbleThinIcon = (props) => {
	return (
		<ToolScribbleThin
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolScribbleMediumIcon = (props) => {
	return (
		<ToolScribbleMedium
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolScribbleThickIcon = (props) => {
	return (
		<ToolScribbleThick
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FlipBackwardBoldIcon = (props) => {
	return (
		<FlipBackwardBold
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FlipForwardBoldIcon = (props) => {
	return (
		<FlipForwardBold
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ReverseLeftIcon = (props) => {
	return (
		<ReverseLeft
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ReverseRightIcon = (props) => {
	return (
		<ReverseRight
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const BroomIcon = (props) => {
	return (
		<Broom
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const EraserIcon = (props) => {
	return (
		<Eraser
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FileExportIcon = (props) => {
	return (
		<FileExport
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const FlipBackwardSlimIcon = (props) => {
	return (
		<FlipBackwardSlim
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const TextTIcon = (props) => {
	return (
		<TextT
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolLinePenIcon = (props) => {
	return (
		<ToolLinePen
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolScribblePenIcon = (props) => {
	return (
		<ToolScribblePen
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};
export const ToolScribbleThickSlimIcon = (props) => {
	return (
		<ToolScribbleThickSlim
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
		/>
	);
};

// FONTAWESOME
// ICONS
export const CaretDownIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M310.625 246.625L182.625 374.625C176.375 380.875 168.188 384 160 384S143.625 380.875 137.375 374.625L9.375 246.625C0.219 237.469 -2.516 223.719 2.438 211.75S19.063 192 32 192H288C300.938 192 312.609 199.781 317.563 211.75S319.781 237.469 310.625 246.625Z' />
		</svg>
	);
};
export const CaretRightIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 256 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M118.625 105.375L246.625 233.375C252.875 239.625 256 247.812 256 256C256 264.187 252.875 272.375 246.625 278.625L118.625 406.625C109.469 415.781 95.719 418.516 83.75 413.562S64 396.937 64 384V128C64 115.062 71.781 103.391 83.75 98.437S109.469 96.219 118.625 105.375Z' />
		</svg>
	);
};
export const CaretUpIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M9.375 265.375L137.375 137.375C143.625 131.125 151.813 128 160 128S176.375 131.125 182.625 137.375L310.625 265.375C319.781 274.531 322.516 288.281 317.563 300.25S300.938 320 288 320H32C19.063 320 7.391 312.219 2.438 300.25S0.219 274.531 9.375 265.375Z' />
		</svg>
	);
};
export const GetSupportIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M213.125 32H106.656C47.844 32 0 79.844 0 138.656V160C0 168.844 7.156 176 16 176S32 168.844 32 160V138.656C32 97.484 65.5 64 106.656 64H213.125C254.406 64 288 97.578 288 138.859C288 165.859 273.375 190.859 249.844 204.109L152.469 258.891C137.375 267.391 128 283.422 128 300.719V336C128 344.844 135.156 352 144 352S160 344.844 160 336V300.719C160 294.953 163.125 289.609 168.156 286.766L265.531 231.984C299.125 213.078 320 177.391 320 138.859C320 79.938 272.062 32 213.125 32ZM144 400C126.326 400 112 414.326 112 432S126.326 464 144 464S176 449.674 176 432S161.674 400 144 400Z' />
		</svg>
	);
};
export const PaperPlaneIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M498.123 5.629C492.68 1.891 486.352 0 480.002 0C474.541 0 469.065 1.398 464.123 4.223L16.123 260.221C5.53 266.268 -0.689 277.814 0.061 289.986S8.436 312.846 19.686 317.533L122.911 360.543L184.623 507.109L184.899 506.992C185.692 508.873 186.916 510.533 188.858 511.359C189.873 511.797 190.936 512 191.998 512C194.03 512 196.014 511.234 197.545 509.766L283.297 427.369L403.686 477.531C407.655 479.188 411.811 480 415.998 480C421.405 480 426.811 478.625 431.655 475.906C440.248 471.078 446.123 462.594 447.623 452.859L511.623 36.863C513.498 24.723 508.248 12.582 498.123 5.629ZM16.032 289.002C15.653 282.861 18.727 277.158 24.063 274.113L472.065 18.113C472.899 17.637 473.903 17.703 474.801 17.385L124.84 344.014L25.84 302.764C20.252 300.436 16.403 295.035 16.032 289.002ZM411.508 98.33L185.764 378.984C184.623 380.406 183.998 382.172 183.998 384V464.375L137.545 354.047L411.508 98.33ZM199.998 485.219V392.664L267.188 420.658L199.998 485.219ZM431.811 450.422C431.055 455.324 428.143 459.527 423.823 461.953C421.438 463.293 418.733 464 415.998 464C413.881 464 411.811 463.584 409.84 462.762L206.918 378.211L492.567 23.086C494.924 26.424 496.452 30.279 495.811 34.43L431.811 450.422Z' />
		</svg>
	);
};
export const ChevronIconLeft = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M206.668 464.592L22.688 272.594C18.219 267.969 16 261.969 16 256.001S18.219 244.032 22.688 239.407L206.668 47.409C215.82 37.815 231.008 37.503 240.57 46.69C250.195 55.815 250.508 71.065 241.289 80.596L73.242 256.001L241.289 431.405C250.508 440.905 250.195 456.186 240.57 465.311C231.008 474.499 215.82 474.186 206.668 464.592Z' />
		</svg>
	);
};
export const ChevronIconRight = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 320 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M113.333 47.409L297.314 239.407C301.783 244.032 304.001 250.032 304.001 256.001S301.783 267.969 297.314 272.594L113.333 464.592C104.181 474.186 88.994 474.499 79.431 465.311C69.806 456.186 69.494 440.936 78.712 431.405L246.759 256.001L78.712 80.596C69.494 71.096 69.806 55.815 79.431 46.69C88.994 37.503 104.181 37.815 113.333 47.409Z' />
		</svg>
	);
};
export const Checkmark = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M480.969 128.969L208.969 400.969C204.281 405.656 198.156 408 192 408S179.719 405.656 175.031 400.969L31.031 256.969C21.656 247.594 21.656 232.406 31.031 223.031S55.594 213.656 64.969 223.031L192 350.062L447.031 95.031C456.406 85.656 471.594 85.656 480.969 95.031S490.344 119.594 480.969 128.969Z' />
		</svg>
	);
};
export const ErrorIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M256 16C123.451 16 16 123.451 16 256S123.451 496 256 496S496 388.549 496 256S388.549 16 256 16ZM64 256C64 211.703 79.225 170.979 104.52 138.457L373.543 407.48C341.021 432.775 300.297 448 256 448C150.131 448 64 361.869 64 256ZM407.48 373.543L138.457 104.52C170.979 79.225 211.703 64 256 64C361.869 64 448 150.131 448 256C448 300.297 432.775 341.021 407.48 373.543Z' />
		</svg>
	);
};
export const CircleSolidIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 512 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M496 256C496 388.548 388.548 496 256 496S16 388.548 16 256S123.452 16 256 16S496 123.452 496 256Z' />
		</svg>
	);
};
export const SaveIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM288 64v96H96V64h192zm128 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V80c0-8.822 7.178-16 16-16h16v104c0 13.255 10.745 24 24 24h208c13.255 0 24-10.745 24-24V64.491a15.888 15.888 0 0 1 7.432 4.195l83.882 83.882A15.895 15.895 0 0 1 416 163.882V432zM224 232c-48.523 0-88 39.477-88 88s39.477 88 88 88 88-39.477 88-88-39.477-88-88-88zm0 144c-30.879 0-56-25.121-56-56s25.121-56 56-56 56 25.121 56 56-25.121 56-56 56z' />
		</svg>
	);
};
export const UserClockIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 640 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z' />
		</svg>
	);
};
export const UserHeadsetIcon = (props) => {
	return (
		<svg
			className={props.iconClass}
			onClick={props.onClick}
			onKeyDown={(e) => handleKeyDown(e, props.onClick)}
			title={props.title}
			tabIndex={props.focusable ? 0 : -1}
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 448 512'>
			Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license
			(Commercial License)
			<path d='M320 352h-23.1a174.08 174.08 0 0 1-145.8 0H128A128 128 0 0 0 0 480a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32 128 128 0 0 0-128-128zM48 224a16 16 0 0 0 16-16v-16c0-88.22 71.78-160 160-160s160 71.78 160 160v16a80.09 80.09 0 0 1-80 80h-32a32 32 0 0 0-32-32h-32a32 32 0 0 0 0 64h96a112.14 112.14 0 0 0 112-112v-16C416 86.13 329.87 0 224 0S32 86.13 32 192v16a16 16 0 0 0 16 16zm160 0h32a64 64 0 0 1 55.41 32H304a48.05 48.05 0 0 0 48-48v-16a128 128 0 0 0-256 0c0 40.42 19.1 76 48.35 99.47-.06-1.17-.35-2.28-.35-3.47a64.07 64.07 0 0 1 64-64z' />
		</svg>
	);
};
