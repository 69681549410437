import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { breakpoints } from '../../../helpers';

export default function ChatTypeTabs({ tabs, selectedTab, setSelectedTab, setSearchParam }) {
	const { width } = useWindowSize();
	return (
		<div className='chatSelectTabs'>
			{[...tabs.values()]?.map((tab, key) => (
				<div
					onClick={() => {
						setSelectedTab(tab.tabIndex);
						setSearchParam({ key: 'tab', value: tab.tabIndex });
					}}
					key={key}
					className={`tabItem${tabs.get(selectedTab)?.type === tab.type ? ' selected' : ''}`}>
					{width > breakpoints.tablet.max ? <h4>{tab.name}</h4> : <>{tab.icon}</>}
				</div>
			))}
		</div>
	);
}
