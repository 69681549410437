import React from 'react';
import EmptyState from '../../../components/EmptyState/EmptyState';

export default function MissingChatState() {
	return (
		<div className='chatBoxCont' id='chatBox'>
			<EmptyState header='No chat selected' body='Please select a chat to start chatting' />
		</div>
	);
}
