import React, { useContext, useEffect, useState } from 'react';
import SelectInput from '../Inputs/SelectInput';
import TextInput from '../Inputs/TextInput';
import DateInput from '../Inputs/DateInput';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useForms } from '../../hooks/useForms';
import * as yup from 'yup';
import Button from '../Button';
import AddressForm from './AddressForm';
import { parseToOptions } from '../../helpers';
import config from '../../config';
import Axios from 'axios';
import { useModalsGlobal } from '../../hooks/useModalsGlobal';
import TextareaInput from '../Inputs/TextareaInput';

export default function PackageCreateForm({ options, hideModal }) {
	const { callback, submitUrl, method, data, addressForm, origin } = options || {};
	const formValidationSchema = yup.object().shape({
		address: yup.object().required('{{address}}{{{Address is required}}}'),
		recipient: yup.string(),
		senderTeamUuid: yup.string(),
		recipientTeamUuid: yup.string(),
		courierUuid: yup.string(),
		quotedCollectionDateTime: yup.date().required('{{quotedCollectionDateTime}}{{{Pickup date is required}}}'),
		quotedDeliveryDateTime: yup.date(),
	});

	const { showModal } = useModalsGlobal();
	const { teamState } = useContext(CurrentTeamContext);

	const [couriers, setCouriers] = useState([]);
	const [trustedAddresses, setTrustedAddresses] = useState([]);
	const [addressFormOpen, setAddressFormOpen] = useState(false);

	let packageCreateMap = (formData) => {
		return {
			origin: origin ? origin : 0,
			note: formData.note,
			address: formData.address.value,
			recipient: formData.recipient,
			senderTeamUuid: teamState?.currentTeam?.teamUuid,
			recipientTeamUuid: formData.address?.teamUuid,
			courierUuid: formData.courierUuid,
			quotedCollectionDateTime: formData.quotedCollectionDateTime,
			quotedDeliveryDateTime: formData.quotedDeliveryDateTime,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'packageCreate',
		formValidationSchema,
		packageCreateMap,
		submitUrl,
		method
	);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleFormSelectData = (data, fieldName) => {
		updateForm({
			...formData,
			[fieldName]: data,
		});
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `Package created successfully`, callback);
		if (success === true) {
			hideModal();
		}
	};

	const handleAddressInput = (value, name) => {
		updateForm({
			...formData,
			['address']: { ...formData.address, [name]: value },
		});
	};

	async function getTrustedAddresses() {
		async function addRequest(url) {
			return await Axios({
				url: url,
				method: 'GET',
			});
		}

		let trusted = teamState.currentTeam?.trustedSuppliers;
		let fetchedTrust = [];
		let reqs = [];
		for (let i = 0; i < trusted?.length; i++) {
			reqs.push(addRequest(`${config.apiv1}/team/team.read/${trusted[i].teamUuid}`));
		}
		if (reqs.length > 0) {
			try {
				await Axios.all(reqs).then(
					Axios.spread((...responses) => {
						for (let i = 0; i < responses.length; i++) {
							if (responses[i].data) {
								console.log(responses[i].data);
								fetchedTrust.push({
									supplierName: responses[i]?.data?.data?.teamInfo?.teamName,
									...responses[i]?.data?.data?.teamInfo?.addresses[0],
								});
							}
						}
					})
				);
			} catch (err) {
				return err;
			}
		}
		setTrustedAddresses(() => {
			return fetchedTrust;
		});
	}

	const getCouriers = () => {
		try {
			Axios({
				url: `${config.apiv1}/courier/couriers.read/${teamState.currentTeam.teamUuid}`,
				method: 'GET',
			})
				.then((response) => {
					if (response?.data?.data) {
						setCouriers(response?.data?.data);
					}
				})
				.catch((err) => {
					return err;
				});
		} catch (err) {
			return err;
		}
	};

	useEffect(() => {
		getTrustedAddresses();
		getCouriers();
	}, []);

	useEffect(() => {
		if (data) {
			updateForm({ ...data });
		}
	}, []);

	return (
		<>
			<form id='packageCreate'>
				<h2>Add new package</h2>
				<SelectInput
					options={[...parseToOptions(couriers, 'couriers'), { name: 'Other', value: 'other' }]}
					text={'Courier'}
					name={'courierUuid'}
					callback={(data, fieldname) => {
						data !== 'other' ? handleFormData('', 'courierName') : null;
						handleFormSelectData(data.value, fieldname);
					}}
					missingOptions={{
						message: 'Add a courier',
						callback: () => {
							showModal('createCourier', {
								callback: (e) => {
									if (e?.data?.data) {
										getCouriers();
									}
								},
								submitUrl: `${config.apiv1}/courier/courier.create`,
								method: 'POST',
							});
						},
					}}
					customError={customErrors?.courierUuid}
				/>
				{formData?.courierUuid === 'other' && (
					<TextInput
						text={'Courier name'}
						name={'courierName'}
						value={formData?.courierName}
						callback={handleFormData}
						controlled
					/>
				)}
				<Button
					text='Add new courier'
					onClick={() =>
						showModal('createCourier', {
							callback: (e) => {
								if (e?.data?.data) {
									getCouriers();
								}
							},
							submitUrl: `${config.apiv1}/courier/courier.create`,
							method: 'POST',
						})
					}
				/>
				<DateInput
					text={'Pickup'}
					name={'quotedCollectionDateTime'}
					callback={handleFormData}
					defaultValue={formData?.quotedCollectionDateTime}
					customError={customErrors?.quotedCollectionDateTime}
					dateFormat='Pp'
					min={new Date()}
					timeSelect
					required
				/>
				<DateInput
					text={'Delivery'}
					name={'quotedDeliveryDateTime'}
					callback={handleFormData}
					defaultValue={formData?.quotedDeliveryDateTime}
					dateFormat='Pp'
					min={new Date()}
					timeSelect
				/>
				<h3 className='margin-10'>Destination</h3>
				<TextInput
					text={'Recipient'}
					name={'recipient'}
					value={formData?.recipient}
					callback={handleFormData}
					controlled
				/>
				{addressFormOpen === true || addressForm === true ? (
					<>
						<AddressForm
							address={formData?.address}
							handleAddressInput={handleAddressInput}
							customErrors={customErrors}
						/>
						<button
							className='btn3-secondary marginTop-20'
							onClick={() => {
								setAddressFormOpen(false);
							}}>
							Choose from dropdown
						</button>
					</>
				) : (
					<>
						<SelectInput
							options={parseToOptions(trustedAddresses, 'supplierAddresses')}
							callback={handleFormSelectData}
							customError={customErrors?.address}
							text={'Supplier addresses'}
							name={'address'}
							required
						/>
						{formData?.address && (
							<div className='chosenAddress'>
								{formData?.address?.value?.name}, {formData?.address?.value?.addressLine1},{' '}
								{formData?.address?.value?.city}, {formData?.address?.value?.postalCode},{' '}
								{formData?.address?.value?.country}
							</div>
						)}
						<button
							className='btn3-secondary marginTop-20'
							onClick={() => {
								setAddressFormOpen(true);
							}}>
							Add custom address
						</button>
					</>
				)}

				<TextareaInput text={'Note'} name={'note'} defaultValue={formData?.note} callback={handleFormData} />
			</form>
			<div className='btnCont'>
				<Button
					size='md'
					style='primary'
					text='Submit'
					onClick={handleSubmit}
					loading={reqLoading}
					minWidth={135}
				/>
			</div>
		</>
	);
}
