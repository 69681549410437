import React from 'react';
import PackageChoiceItem from './PackageChoiceItem';
import Button from '../Button';
import { ArrowLeftIcon, ArrowNarrowLeftIcon, PlusIcon } from '../SVGIcons/SVGIcons';
import EmptyState from '../EmptyState/EmptyState';

export default function PackageChoiceList({ options, hideModal }) {
	return (
		<>
			{options?.packages?.length === 0 ? (
				<div className='emptyStateOuterCont'>
					<EmptyState
						header={'No packages found'}
						type='packages'
						body='You have no packages. Start by creating one.'
						btn1={{
							text: 'Go back',
							icon: <ArrowNarrowLeftIcon />,
							callback: () => {
								hideModal();
							},
						}}
						btn2={{
							text: 'Create package',
							icon: <PlusIcon />,
							callback: () => {
								hideModal();
								options?.openPackageNewModal();
							},
						}}
						thin
					/>
				</div>
			) : (
				<div className='packageChoiceList'>
					<Button
						text={'Create package'}
						iconLeft={<PlusIcon />}
						size='lg'
						style='accent'
						maxWidth={204}
						onClick={() => {
							hideModal();
							options?.openPackageNewModal();
						}}
					/>
					{options?.packages?.map((pack, key) => {
						return (
							<PackageChoiceItem
								pack={pack}
								hideModal={hideModal}
								callback={(e) => {
									options?.callback(e);
									hideModal();
								}}
								key={key}
								choice
							/>
						);
					})}
				</div>
			)}
		</>
	);
}
