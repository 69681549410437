import React from 'react';
import FilterTabs from './FilterTabs';
import ActionsBar from './ActionsBar';
import {
	ArrowLeftIcon,
	BoxesStackedIcon,
	ClipboardInfoIcon,
	FileLinesIcon,
	InvoiceInfoIcon,
	PrintIcon,
} from '../SVGIcons/SVGIcons';
import Button from '../Button';
import ListingPagination from '../Pagination/ListingPagination';
import InvoiceListItems from './ListItemTypes/InvoiceListItems';
import OrderListItems from './ListItemTypes/OrderListItems';
import InvoicesFilterBar from './FilterBars/InvoicesFilterBar';
import OrdersFilterBar from './FilterBars/OrdersFilterBar';
import LogListItems from './ListItemTypes/LogListItems';
import LogsFilterBar from './FilterBars/LogsFilterBar';
import PackageListItems from './ListItemTypes/PackageListItems';
import PackagesFilterBar from './FilterBars/PackagesFilterBar';
import { useLocation, useNavigate, useParams } from 'react-router';

export default function Listing(props) {
	const {
		type,
		listItems,
		loading,
		handlePagination,
		pages,
		handleSearch,
		handleFilterSelect,
		handleFilter,
		selectedTabs,
		setSelectedTabs,
		openPackageCreateModal,
		openCourierCreateModal,
		openFilter,
		filter,
		search,
		options,
	} = props || {};

	let location = useLocation();
	let navigate = useNavigate();

	let returnUri = new URLSearchParams(location.search).get('returnUri');

	return (
		<div className='pageInnerCont gray'>
			<div className='listing'>
				<div className='header'>
					<div className='flexRow'>
						{type === 'orders' && (
							<>
								<ClipboardInfoIcon iconClass='mainIcon' />
								<h1>Orders</h1>
							</>
						)}
						{type === 'invoices' && (
							<>
								<InvoiceInfoIcon iconClass='mainIcon' />
								<h1>Invoices</h1>
							</>
						)}
						{type === 'logs' && (
							<>
								<ClipboardInfoIcon iconClass='mainIcon' />
								<h1>Order history log</h1>
							</>
						)}
						{type === 'packages' && (
							<>
								<BoxesStackedIcon iconClass='mainIcon' />
								<h1>Packages</h1>
							</>
						)}
					</div>
					{returnUri ? (
						<Button
							onClick={() => navigate(decodeURI(returnUri))}
							size='md'
							style='secondary'
							iconLeft={<ArrowLeftIcon />}
							maxWidth={40}
							minWidth={40}
							tooltip='Return to previous page'
						/>
					) : (
						<></>
					)}
					{/* <Button
						btnText='Print'
						size='md'
						style='secondary'
						iconLeft={<PrintIcon />}
						minWidth={100}
						disabled
					/> */}
				</div>
				<div className='inner'>
					{type !== 'logs' && type !== 'packages' && (
						<FilterTabs
							leftGroup={options?.leftGroup}
							rightGroup={options?.rightGroup}
							selectedTabs={selectedTabs}
							setSelectedTabs={setSelectedTabs}
						/>
					)}

					{type === 'orders' && (
						<OrdersFilterBar
							handleFilterSelect={handleFilterSelect}
							handleSearch={handleSearch}
							handleFilter={handleFilter}
							openFilter={openFilter}
							filter={filter}
						/>
					)}
					{type === 'invoices' && (
						<InvoicesFilterBar
							handleFilterSelect={handleFilterSelect}
							handleSearch={handleSearch}
							handleFilter={handleFilter}
							openFilter={openFilter}
							filter={filter}
						/>
					)}
					{type === 'logs' && (
						<LogsFilterBar
							handleFilterSelect={handleFilterSelect}
							handleSearch={handleSearch}
							handleFilter={handleFilter}
							openFilter={openFilter}
							search={search}
							filter={filter}
						/>
					)}
					{type === 'packages' && (
						<PackagesFilterBar
							handleFilterSelect={handleFilterSelect}
							openPackageCreateModal={openPackageCreateModal}
							openCourierCreateModal={openCourierCreateModal}
							handleSearch={handleSearch}
							handleFilter={handleFilter}
							openFilter={openFilter}
							listItems={listItems}
							search={search}
							filter={filter}
						/>
					)}

					{/* <ActionsBar /> */}
					{type === 'orders' && <OrderListItems listItems={listItems} loading={loading} />}
					{type === 'invoices' && <InvoiceListItems listItems={listItems} loading={loading} />}
					{type === 'logs' && <LogListItems listItems={listItems} loading={loading} />}
					{type === 'packages' && <PackageListItems listItems={listItems} loading={loading} />}

					{pages > 0 && listItems && listItems.length !== 0 && (
						<ListingPagination handleClick={handlePagination} pages={pages} />
					)}
				</div>
			</div>
		</div>
	);
}
