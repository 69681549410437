import React, { useContext, useEffect, useState } from 'react';
import TextInput from '../Inputs/TextInput';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import { useForms } from '../../hooks/useForms';
import * as yup from 'yup';
import Button from '../Button';
import TextareaInput from '../Inputs/TextareaInput';

export default function CourierCreateForm({ options, hideModal }) {
	const { callback, submitUrl, method } = options || {};
	const formValidationSchema = yup.object().shape({
		companyName: yup.string().required('{{companyName}}{{{Company name is required}}}'),
		email: yup.string(),
		telephone: yup.string(),
		accountNmbr: yup.string(),
		notes: yup.string(),
	});

	const { teamState } = useContext(CurrentTeamContext);

	let courierCreateMap = (formData) => {
		return {
			companyName: formData.companyName,
			email: formData.email,
			telephone: formData.telephone,
			accountNmbr: formData.accountNmbr,
			notes: formData.notes,
			teamUuid: teamState?.currentTeam?.teamUuid,
		};
	};

	const {
		formData = {},
		updateForm,
		submitForm,
		reqLoading,
		customErrors,
	} = useForms('courierCreate', formValidationSchema, courierCreateMap, submitUrl, method);

	const handleFormData = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `Courier created successfully`, callback);
		if (success === true) {
			hideModal();
		}
	};

	return (
		<>
			<form id='courierCreate'>
				<h2>Add new courier</h2>
				<TextInput
					text={'Company name'}
					name={'companyName'}
					callback={handleFormData}
					defaultValue={formData.companyName}
					customError={customErrors?.companyName}
					required
				/>
				<TextInput
					text={'Email'}
					name={'email'}
					callback={handleFormData}
					defaultValue={formData.email}
					customError={customErrors?.email}
					required
				/>
				<TextInput
					text={'Telephone'}
					name={'telephone'}
					callback={handleFormData}
					defaultValue={formData.telephone}
					customError={customErrors?.telephone}
					required
				/>
				<TextInput
					text={'Account'}
					name={'accountNmbr'}
					callback={handleFormData}
					defaultValue={formData.accountNmbr}
					customError={customErrors?.accountNmbr}
					required
				/>
				<TextareaInput
					text={'Notes'}
					name={'notes'}
					defaultValue={formData?.notes}
					callback={handleFormData}
					customError={customErrors?.notes}
				/>
			</form>
			<div className='btnCont'>
				<Button
					size='md'
					style='primary'
					text='Submit'
					onClick={handleSubmit}
					loading={reqLoading}
					minWidth={135}
				/>
			</div>
		</>
	);
}
