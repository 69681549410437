import Axios from 'axios';
import React, { useState } from 'react';
import { useContext } from 'react';
import config from '../../config';
import { CurrentTeamContext } from '../../contexts/CurrentTeamContext';
import Loading from '../../components/Loading/Loading';
import PermissionsForm from '../../components/Forms/PermissionsForm';
import TextInput from '../../components/Inputs/TextInput';
import TextareaInput from '../../components/Inputs/TextareaInput';
import Button from '../../components/Button';
import { ModalContext } from '../../contexts/ModalContext';

export default function Invite(props) {
	const { handleFadeOutModal, handleModal, setUpdateLoading, fetchInvitations, members } = props || {};
	const [permissions, setPermissions] = useState({});
	const [customErrors, setCustomErrors] = useState({});
	const [role, setRole] = useState(null);

	const [loading, setLoading] = useState(false);
	const [responseMsg, setResponseMsg] = useState(null);
	const [responseErrorMsg, setResponseErrorMsg] = useState(null);
	const [formData, setFormData] = useState({});

	const { teamState } = useContext(CurrentTeamContext);
	const { flashFadeModal } = useContext(ModalContext);

	function sendInvites(invites) {
		function checkInvalidFields() {
			let invalid = false;
			const elements = document.querySelectorAll('[isrequired]');
			for (let i = 0; i < elements.length; i++) {
				if (!formData[elements[i].getAttribute('isrequired')]) {
					setCustomErrors({
						...customErrors,
						[elements[i].getAttribute('isrequired')]: 'Please fill in the required field',
					});
					elements[i].scrollIntoView({ behavior: 'smooth', block: 'center' });
					return (invalid = true);
				}
			}
			return invalid;
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		let invalid = checkInvalidFields();

		const invalidEmail = emailRegex.test(invites[0]?.recipientEmail) === false;

		if (invalidEmail) {
			return setCustomErrors({
				...customErrors,
				email: 'Email is invalid',
			});
		}

		if (invalid) {
			return;
		}
		if (!invites) {
			return;
		}
		if (invites?.length > 0 && !invites[0]?.role) {
			flashFadeModal('Please assign a role');
			return;
		}
		if (invites?.length > 0 && Object.keys(invites[0]?.permissions || {})?.length === 0) {
			flashFadeModal('Please fill in permissions');
			return;
		}

		let match = members.filter((member) => member.email === formData.email);

		if (match.length !== 0) {
			handleFadeOutModal({
				show: true,
				isFading: false,
				message: 'A user with that email is already a part of your team',
			});
			return;
		}

		setUpdateLoading(true);
		setLoading(true);

		Axios({
			url: `${config.apiv1}/email/member.invite`,
			method: 'POST',
			data: {
				recipientName: formData.recipientName,
				senderName: teamState.currentTeam.teamInfo.teamName,
				senderTeamUuid: teamState.currentTeam.teamUuid,
				invitations: invites,
			},
		})
			.then((res) => {
				if (res.data.ok) {
					handleModal('inviteModal', false);
					handleFadeOutModal({
						show: true,
						isFading: false,
						message: 'Invitation successfully sent',
					});
					setPermissions({});
					setFormData({});
					setRole(null);
					setResponseMsg('Invitation successfully sent!');
					setTimeout(() => {
						fetchInvitations();
					}, 1000);
				} else {
					setResponseErrorMsg('Something went wrong when sending the invitation');
					setResponseMsg('');
				}
				setLoading(false);
			})
			.catch((err) => {
				handleFadeOutModal({
					show: true,
					isFading: false,
					message: 'Something went wrong when sending the invitation',
				});
				setResponseErrorMsg('Something went wrong when sending the invitation');
				setUpdateLoading(false);
				setResponseMsg('');
				setLoading(false);
				return err;
			});
	}

	function checkEmail() {
		if (email.includes('@') && email.split('@')[1]) {
			return true;
		}
	}

	const handlePermissionsInput = (checked, name) => {
		setPermissions((permissions) => ({
			...permissions,
			[name]: checked,
		}));
	};

	const handleFormData = (value, name) => {
		setCustomErrors({});
		setFormData((formData) => ({
			...formData,
			[name]: value,
		}));
	};

	return (
		<div className='mainFormCont' id='inviteForm'>
			<h2 className='formTitle'>Invite someone into your team</h2>
			<h2 className='formLightTitle'>
				Add their name, email address and optionally a personalised greeting below. Then set their permissions.
				When you press invite they are sent an invitation email from Procuur inviting them to join your Team. If
				they press the accept button in that email and register with Procuur they automatically become a Member
				of your Team.
			</h2>
			<div className='formSection'>
				{responseMsg ? <h4>{responseMsg}</h4> : null}
				{responseErrorMsg ? <h4 className='error'>{responseErrorMsg}</h4> : null}
				{loading && <Loading type='circle' />}
				<TextInput
					customError={customErrors.recipientName}
					defaultValue={formData.recipientName}
					text={'Recipient name:'}
					name={'recipientName'}
					callback={handleFormData}
					required
				/>
				<TextInput
					customError={customErrors.email}
					value={formData.email}
					callback={(value, name) => {
						value = value.replace(/\s+/g, '').toLocaleLowerCase();
						handleFormData(value, name);
					}}
					text={'Email:'}
					name={'email'}
					controlled
					required
				/>
				<TextareaInput
					customError={customErrors.customText}
					defaultValue={formData.customText}
					text='Personalised greeting: '
					callback={handleFormData}
					name={'customText'}
				/>
			</div>
			<div className='formSection'>
				<PermissionsForm
					handlePermissionsInput={handlePermissionsInput}
					setPermissions={setPermissions}
					permissions={permissions}
					setRole={setRole}
					role={role}
					customErrors={customErrors}
					required
				/>
			</div>
			<div className='formSubmit'>
				<div className='btnCont'>
					<Button
						size='md'
						style='primary'
						text='Send invite'
						disabled={loading}
						onClick={() => {
							sendInvites([
								{
									recipientName: formData.recipientName,
									recipientEmail: formData.email,
									role: role,
									permissions: permissions,
									customText: formData.customText,
								},
							]);
						}}
					/>
				</div>
			</div>
			{/* <div className='formSection'>
						{responseMsg ? <h4>{responseMsg}</h4> : null}
						{responseErrorMsg ? <h4 className='error'>{responseErrorMsg}</h4> : null}
						{loading && <Loading type='circle' />}
						<div className='inputRow'>
							<p className='bold' htmlFor='email'>
								Email:
							</p>
							<div className='inputContainer'>
								<input
									className='textFieldInput'
									type='email'
									name='email'
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									required
								/>
								{email !== '' && checkEmail() ? (
									<Checkmark iconClass={'inputIcon'} />
								) : (
									<ErrorIcon iconClass={'inputIcon'} />
								)}
							</div>
						</div>
						<div className='formColumnCenter'>
							<textarea
								className='formTextarea'
								value={customText}
								cols='30'
								rows='10'
								onChange={(e) => {
									setCustomText(e.target.innerText || '');
								}}></textarea>
						</div>
						<PermissionsForm
							role={role}
							permissions={permissions}
							setRole={setRole}
							setPermissions={setPermissions}
							handlePermissionsInput={handlePermissionsInput}
							required={true}
						/>
					</div>
					<button
						className='functionalButtonSecondary'
						onClick={() => {
							addInviteToList({
								recipientEmail: email,
								role: role,
								permissions: permissions,
								customText: customText,
							});
							setRole(null);
							setCustomText('');
							setPermissions({});
						}}>
						Add to list
					</button>
					<div className='multiInviteList'>
						{invitesList.map((invite, key) => {
							return (
								<React.Fragment key={key}>
									<h4>{invite.recipientEmail}</h4>
								</React.Fragment>
							);
						})}
					</div>
					{invitesList.length > 0 && (
						<button
							className='functionalButtonPrimary'
							disabled={loading}
							onClick={() => {
								sendInvites(invitesList);
							}}>
							Send invitations
						</button>
					)} */}
		</div>
	);
}
