import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router';
import { splitUserId } from '../helpers';
import Decode from 'jwt-decode';
import Cookies from 'js-cookie';
import Axios from 'axios';
import config from '../config';
import Loading from '../components/Loading/Loading';

let CurrentUserContext = React.createContext();

function CurrentUserContextProvider(props) {
	let initialState = {
		loading: true,
		reload: false,
		isAuthenticated: false,
		users: [],
		currUser: null,
		lastCode: null,
	};
	const minute = 60000;
	const day = 86400000;

	const location = useLocation();

	let navigate = useNavigate();

	const reducer = (userState, action) => {
		switch (action.type) {
			case 'LOGOUT':
				let isAuthenticated = true;
				if (!userState.logout) {
					let user = action.payload;
					let currUser = localStorage.getItem('currentUser');
					localStorage.removeItem(`${user || currUser}.refreshToken`);
					localStorage.removeItem(`${user || currUser}.accessToken`);
					localStorage.removeItem(`${user || currUser}.idToken`);

					let filteredUsers = userState.users.filter((user) => {
						return splitUserId(user.sub) !== currUser;
					});

					userState.logout = true;

					if (filteredUsers.length > 0) {
						if (filteredUsers[0]?.sub) {
							localStorage.setItem(`currentUser`, splitUserId(filteredUsers[0].sub));
						}
						navigate('/');
					}

					window.location.reload();
				}

				return {
					...userState,
					loading: false,
					reload: true,
					isAuthenticated: isAuthenticated,
				};
			case 'RELOAD_AUTHENTICATED':
				const redirectAfterAuth = Cookies.get('redirectAfterAuth');
				if (redirectAfterAuth !== undefined) {
					let parsedData = JSON.parse(redirectAfterAuth);
					if (!parsedData.redirected && parsedData.redirectUri) {
						parsedData.redirected = true;
						Cookies.set('redirectAfterAuth', JSON.stringify(parsedData));
						window.location.assign(
							`${parsedData.redirectUri}?invitationUuid=${parsedData.params.invitationUuid}&teamUuid=${parsedData.params.teamUuid}`
						);
					} else if (parsedData.redirectHref) {
						parsedData.redirected = true;
						Cookies.remove('redirectAfterAuth', JSON.stringify(parsedData));
						window.location.assign(parsedData.redirectHref);
					}
				}
				return {
					...userState,
					loading: false,
					reload: true,
					isAuthenticated: true,
				};
			case 'GO_TO_PUBLIC':
				if (!window.location.pathname?.includes('/features')) {
					window.location.assign('/features');
				}
				return {
					...userState,
					loading: false,
					reload: false,
					isAuthenticated: false,
				};
			case 'LOGOUT_ALL':
				const identifiers = ['accessToken', 'idToken', 'refreshToken'];
				for (let store in localStorage) {
					let tokenIdentifier = store.split('.');
					if (identifiers.includes(tokenIdentifier[1])) {
						localStorage.removeItem(store);
					}
				}
				navigate('/features');

				return {
					...userState,
					loading: false,
					reload: true,
					isAuthenticated: false,
				};
			case 'LOGIN': {
				const lastState = uuidv4();
				Cookies.set(`lastState`, lastState, { expires: 0.2 });
				if (window.location.href.includes('/features')) {
					Cookies.set(
						`redirectAfterAuth`,
						JSON.stringify({
							isRedirect: false,
							redirectHref: '/',
						}),
						{ expires: 1 }
					);
				}

				const redirect = new URL(`${config.auth}/login`);
				redirect.searchParams.set('redirect_uri', props.redirectUri);
				redirect.searchParams.set('response_type', 'code');
				redirect.searchParams.set('env', process.env.REACT_APP_ENV);

				redirect.searchParams.set('regType', 'login');
				redirect.searchParams.set('client_id', props.clientId);
				redirect.searchParams.set('scope', props.scope);
				redirect.searchParams.set('state', lastState);

				if (action.payload === true) {
					redirect.searchParams.set('regCode', 'bm~63T]X7mqVpygV');
				}

				window.location.assign(redirect);
				return {
					...userState,
				};
			}
			case 'REGISTER': {
				const lastState = uuidv4();
				let invitingTeamUuid = new URLSearchParams(location.search).get('teamUuid') || '';
				let registerEmail = new URLSearchParams(location.search).get('registerEmail') || '';
				let recipientType = new URLSearchParams(location.search).get('recipientType') || '';
				let regCode = new URLSearchParams(location.search).get('regCode') || '';
				Cookies.set(`lastState`, lastState, { expires: 0.2 });
				const redirect = new URL(`${config.auth}/login`);
				redirect.searchParams.set('redirect_uri', props.redirectUri);
				redirect.searchParams.set('response_type', 'code');
				redirect.searchParams.set('env', process.env.REACT_APP_ENV);
				redirect.searchParams.set('client_id', props.clientId);
				redirect.searchParams.set('scope', props.scope);
				redirect.searchParams.set('state', lastState);
				redirect.searchParams.set('regCode', regCode);
				redirect.searchParams.set('registerEmail', registerEmail);
				redirect.searchParams.set('invitingTeamUuid', invitingTeamUuid);
				redirect.searchParams.set('recipientType', recipientType);
				window.location.assign(redirect);
				return {
					...userState,
				};
			}
			case 'SET_REDIRECT_AFTER_AUTH':
				Cookies.set(`redirectAfterAuth`, JSON.stringify(action.payload), { expires: 0.1 });
				return {
					...userState,
					loading: false,
					reload: false,
				};
			case 'SET_CURR_USER':
				localStorage.setItem(`currentUser`, splitUserId(action.payload));
				if (!Cookies.get('redirectAfterAuth')) {
					if (
						window.location.href.includes('manage') ||
						window.location.href.includes('order') ||
						window.location.href.includes('team-registration')
					) {
						navigate('/');
					}
				}
				return {
					...userState,
					loading: false,
					reload: true,
				};
			case 'SET_LOADING':
				return {
					...userState,
					loading: action.payload,
					reload: false,
				};
			case 'MANAGE':
				window.location.href = `${config.auth}/manage?email=${userState.currUser.email}&redirectUrl=${config.host}`;
				return {
					...userState,
					loading: false,
					reload: false,
				};
			case 'SET_LAST_CODE':
				return {
					...userState,
					loading: false,
					reload: false,
					lastCode: action.payload,
				};
			case 'LOGIN_SUCCESS':
				let userData = extractUserData(action.payload.data.id_token);
				let users = [];

				if (userData) {
					localStorage.setItem(
						`${splitUserId(userData.sub)}.refreshToken`,
						action.payload.data.refresh_token
					);
					localStorage.setItem(`${splitUserId(userData.sub)}.idToken`, action.payload.data.id_token);
					localStorage.setItem(`${splitUserId(userData.sub)}.accessToken`, action.payload.data.access_token);
					localStorage.setItem(`currentUser`, splitUserId(userData.sub));

					if (
						!Cookies.get('redirectAfterAuth') ||
						JSON.parse(Cookies.get('redirectAfterAuth')).redirectHref === '/'
					) {
						Cookies.set(`recentLogin`, true, { expires: 1 });
					}

					Axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.data.access_token}`;

					setTimeout(() => {
						getNewTokens();
					}, day - minute);

					for (let store in localStorage) {
						if (store.split('.')[1] === 'idToken') {
							let decoded = extractUserData(localStorage[store]);
							handleIdTokens(decoded);
						}
					}

					if (action?.payload?.redirect) {
						action.payload.redirect = JSON.parse(action?.payload?.redirect);
					}

					if (action?.payload?.redirect?.redirectUri) {
						navigate(action?.payload?.redirect?.redirectUri);
					}
				} else {
					return {
						...userState,
						loading: false,
						reload: true,
						isAuthenticated: false,
					};
				}

				return {
					...userState,
					loading: true,
					reload: false,
					isAuthenticated: true,
					currUser: userData,
					users: [...userState.users, ...users],
				};
			case 'SET_USER_DATA':
				return {
					...userState,
					loading: false,
					reload: true,
					currUser: { ...userState.currUser, ...action.payload },
				};
			default:
				return { ...userState };
		}
	};

	function checkIfTokenExpired(token) {
		if (String(token) !== 'null' && String(token) !== 'undefined') {
			const decodedToken = Decode(token);
			const currDate = new Date();
			if (decodedToken.exp < currDate.getTime() / 1000) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	function extractUserData(idToken) {
		if (String(idToken) !== 'null' && String(idToken) !== 'undefined') {
			return Decode(idToken);
		} else {
			return false;
		}
	}

	let [userState, userDispatch] = React.useReducer(reducer, initialState);
	let value = { userState, userDispatch, getNewTokens };

	async function getNewTokens() {
		let currentUser = localStorage.getItem('currentUser');
		if (!currentUser) {
			for (let item in localStorage) {
				if (item.includes('.refreshToken')) {
					let userUuid = item.split('.')[0];
					currentUser = userUuid;
					localStorage.setItem('currentUser', userUuid);
				}
			}
		}
		const token = localStorage.getItem(`${currentUser}.refreshToken`);

		try {
			let res = await Axios({
				url: `${config.auth}/v1/oauth/token`,
				method: 'POST',
				data: {
					refresh_token: token,
					client_id: props.clientId,
					redirect_uri: window.location.origin,
					grant_type: 'refresh_token',
					scope: 'openid profile email offline_access',
				},
			});
			userState.currUser = extractUserData(res?.data?.id_token);

			Axios.defaults.headers.common['Authorization'] = `Bearer ${res?.data?.access_token}`;

			localStorage.setItem(`${res?.data?.userId}.refreshToken`, res?.data?.refresh_token);
			localStorage.setItem(`${res?.data?.userId}.idToken`, res?.data?.id_token);
			localStorage.setItem(`${res?.data?.userId}.accessToken`, res?.data?.access_token);

			setTimeout(() => {
				getNewTokens();
			}, day - minute);

			for (let store in localStorage) {
				if (store.split('.')[1] === 'idToken') {
					let decoded = extractUserData(localStorage[store]);
					handleIdTokens(decoded);
				}
			}
		} catch (err) {
			userDispatch({ type: 'LOGOUT' });
			return err;
		}
		userDispatch({ type: 'RELOAD_AUTHENTICATED' });
	}

	async function handleIdTokens(idToken) {
		if (String(idToken) !== 'null' && String(idToken) !== 'undefined') {
			if (idToken?.avatar?.path) {
				try {
					let res = await Axios({
						url: `${config.auth}/file?path=${idToken.avatar.path}`,
						method: 'GET',
						responseType: 'blob',
					});
					if (res.status === 200) {
						idToken.avatar.url = window.URL.createObjectURL(new Blob([res.data]));
						if (userState?.currUser.sub !== idToken.sub) {
							idToken.avatar = idToken.avatar;
							userState.users.push(idToken);
						} else {
							userState.currUser.avatar = idToken.avatar;
						}
						userDispatch({ type: 'SET_LOADING', payload: false });
					}
				} catch (err) {
					console.log(err);
					setTimeout(() => {
						userState?.users.push(idToken);
						userDispatch({ type: 'SET_LOADING', payload: false });
					}, 500);
				}
			} else {
				setTimeout(() => {
					userState?.users.push(idToken);
					userDispatch({ type: 'SET_LOADING', payload: false });
				}, 500);
			}
		}
	}

	function saveUrlForRedirect() {
		Cookies.set(
			`redirectAfterAuth`,
			JSON.stringify({
				isRedirect: false,
				redirectHref: window.location.href,
			}),
			{ expires: 0.1 }
		);
	}

	useEffect(() => {
		const invitationUuid = new URLSearchParams(location.search).get('invitationUuid');
		const teamUuid = new URLSearchParams(location.search).get('teamUuid');
		const redirect = new URLSearchParams(location.search).get('redirect');
		const regCode = new URLSearchParams(location.search).get('regCode');
		const state = new URLSearchParams(location.search).get('state');
		const code = new URLSearchParams(location.search).get('code');
		let currentUser = localStorage.getItem('currentUser');
		if (!currentUser) {
			for (let item in localStorage) {
				if (item.includes('.refreshToken')) {
					let userUuid = item.split('.')[0];
					currentUser = userUuid;
					localStorage.setItem('currentUser', userUuid);
				}
			}
		}
		const lastState = Cookies.get('lastState');

		const redirectAfterAuth = Cookies.get('redirectAfterAuth');
		const refreshToken = localStorage.getItem(`${currentUser}.refreshToken`);

		if (redirectAfterAuth && JSON.parse(redirectAfterAuth).redirectCount === 2) {
			Cookies.remove('redirectAfterAuth');
		}

		if (redirect === 'login') {
			return userDispatch({ type: 'LOGIN' });
		} else if (redirect === 'confirm') {
			Cookies.set(
				`redirectAfterAuth`,
				JSON.stringify({
					isRedirect: true,
					redirectUri: '/confirm-member',
					params: { invitationUuid: invitationUuid, teamUuid: teamUuid },
				}),
				{ expires: 0.1 }
			);
		}

		if (regCode) {
			return userDispatch({ type: 'REGISTER' });
		}

		if (code && userState.lastCode !== code && state === lastState && !location.pathname.includes('/redirects/')) {
			Cookies.remove(`lastState`);
			Axios({
				url: `${config.auth}/v1/oauth/token`,
				method: 'POST',
				data: {
					code: code,
					client_id: props.clientId,
					redirect_uri: window.location.origin,
					grant_type: 'authorization_code',
				},
			})
				.then((res) => {
					if (res.data) {
						userDispatch({ type: 'SET_LAST_CODE', payload: code });
						if (redirectAfterAuth) {
							userDispatch({
								type: 'LOGIN_SUCCESS',
								payload: { data: res.data, redirect: redirectAfterAuth },
							});
						} else {
							userDispatch({ type: 'LOGIN_SUCCESS', payload: { data: res.data } });
						}
					}
				})
				.catch((err) => {
					return err;
				});
		} else if (refreshToken) {
			if (!checkIfTokenExpired(refreshToken)) {
				getNewTokens();
			} else {
				return userDispatch({ type: 'LOGIN' });
			}
		} else {
			saveUrlForRedirect();
			userDispatch({ type: 'SET_LOADING', payload: false });
		}
	}, []); // eslint-disable-next-line

	if (userState?.loading) {
		return <Loading type='circle' />;
	}

	return <CurrentUserContext.Provider value={value}>{props.children}</CurrentUserContext.Provider>;
}

let CurrentUserContextConsumer = CurrentUserContext.Consumer;

export { CurrentUserContext, CurrentUserContextProvider, CurrentUserContextConsumer };
