import React from 'react';

export default function ChatWritingAnimation() {
	return (
		<div className='chatWritingCont'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 60 60'
				width='60'
				height='60'
				fill='#575D5E'
				aria-label='Typing indicator'>
				<circle cx='15' cy='10' r='4'>
					<animate
						attributeName='cy'
						dur='1s'
						begin='0s'
						repeatCount='indefinite'
						values='35;20;35;35'
						keyTimes='0;0.3;0.6;1'
					/>
				</circle>
				<circle cx='30' cy='10' r='4'>
					<animate
						attributeName='cy'
						dur='1s'
						begin='0.1s'
						repeatCount='indefinite'
						values='35;17.5;35;35'
						keyTimes='0;0.3;0.6;1'
					/>
				</circle>
				<circle cx='45' cy='10' r='4'>
					<animate
						attributeName='cy'
						dur='1s'
						begin='0.2s'
						repeatCount='indefinite'
						values='35;20;35;35'
						keyTimes='0;0.3;0.6;1'
					/>
				</circle>
			</svg>
		</div>
	);
}
