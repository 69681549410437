import React, { useContext, useState } from 'react';
import Loading from '../../Loading/Loading';
import Button from '../../Button';
import { ArrowNarrowRightIcon, ProgressHoldIcon } from '../../SVGIcons/SVGIcons';
import { dateConverter, parseProcessingStatus } from '../../../helpers';
import { useNavigate } from 'react-router';
import EmptyState from '../../EmptyState/EmptyState';
import useWindowSize from '../../../hooks/useWindowSize';

export default function OrderListItems(props) {
	const { listItems, loading } = props || {};
	const [lastMousePress, setLastMousePress] = useState(null);
	const [newMousePress, setNewMousePress] = useState(null);

	let navigate = useNavigate();

	const { width } = useWindowSize();

	function onTouchStart(e) {
		setLastMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchMove(e) {
		setNewMousePress({ x: e.touches[0].clientX, y: e.touches[0].clientY });
	}

	function onTouchEnd(e, item) {
		if (newMousePress?.x === lastMousePress?.x && newMousePress?.y === lastMousePress?.y) {
			navigate(`/orders/${item.orderUuid}?orderRef=${item.orderRef}`);
		}
	}

	return (
		<div className='listOuterCont'>
			<div className='list'>
				<div className='listHeader'>
					<h4>Reference</h4>
					<h4>Hint</h4>
					<h4>Buyer</h4>

					<h4>Due date</h4>
					<h4>Status</h4>
					<h4>Amount</h4>
					{width > 1028 && (
						<h4>
							<div style={{ visibility: 'hidden' }}>
								<Button
									text='See details'
									size='md'
									style='tertiary-secondary'
									iconRight={<ArrowNarrowRightIcon />}
								/>
							</div>
						</h4>
					)}
				</div>
				{!loading ? (
					<>
						{listItems && listItems?.length !== 0 ? (
							listItems.map((item, key) => {
								const iconClass = 'progressIcon';
								return (
									<div
										key={key}
										className='item'
										onTouchStart={onTouchStart}
										onTouchMove={onTouchMove}
										onTouchEnd={(e) => onTouchEnd(e, item)}>
										<p className='data'>{item.orderRef}</p>
										<p className='data'>{item.sellerHint || item.buyerHint}</p>
										<p className='data'>{item.behalfOf?.name}</p>

										<p className='data'>{dateConverter(item.dispatchDueDateTime)}</p>

										<p className='data'>
											{item.hold ? (
												<ProgressHoldIcon iconClass={`${iconClass} warning`} />
											) : (
												parseProcessingStatus(item.orderProcessingStatus, iconClass).icon
											)}
										</p>
										<p className='data'>
											{item.totalPrice ? `${Number(item.totalPrice)?.toFixed(2)}` : '0.00'}
										</p>
										{width > 1028 && (
											<p className='data'>
												<Button
													text='See details'
													size='md'
													style='tertiary-secondary'
													iconRight={<ArrowNarrowRightIcon />}
													onClick={() => {
														navigate(`/orders/${item.orderUuid}?orderRef=${item.orderRef}`);
													}}
												/>
											</p>
										)}
									</div>
								);
							})
						) : (
							<EmptyState body='No orders matched your search filters' header='No orders found' />
						)}
					</>
				) : (
					<Loading type='circle' />
				)}
			</div>
		</div>
	);
}
