import React from 'react';

export default function DisplayCount({ count = 0 }) {
	return (
		<>
			{count !== 0 ? (
				<div className='displayCount'>
					<h4>{count < 100 && count !== 0 ? count : '99+'}</h4>
				</div>
			) : (
				<></>
			)}
		</>
	);
}
