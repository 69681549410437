import React, { forwardRef, useContext } from 'react';
import { clarifyDate, splitUserId, timestampConverter } from '../../../helpers';
import { PaperPlaneIcon, SendIcon } from '../../../components/SVGIcons/SVGIcons';
import Loading from '../../../components/Loading/Loading';
import { CurrentTeamContext } from '../../../contexts/CurrentTeamContext';
import Button from '../../../components/Button';
import ChatWritingAnimation from './ChatWritingAnimation';
import ChatBoxHeader from './ChatBoxHeader';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';

const ChatBox = forwardRef(
	(
		{ room, inputFilled, handleNewMessage, loaded, handleChange, handleScroll, messageFetchLoading },
		{ divEnd, message, chatBox }
	) => {
		const { teamState } = useContext(CurrentTeamContext);
		const { userState } = useContext(CurrentUserContext);

		return (
			<>
				<div className='chatBoxCont' id='chatBox'>
					<>
						{loaded ? (
							<>
								<ChatBoxHeader room={room} />
								<div
									className='chatListCont'
									onScroll={(e) => {
										handleScroll(e);
									}}
									ref={chatBox}>
									{messageFetchLoading && <Loading type='circle' />}
									{room?.messages?.map((message, key) => {
										return (
											<React.Fragment key={message.messageUuid || key}>
												{message.type === 'separator' ? (
													<div className='separator'>
														<h4>{clarifyDate(message.date)}</h4>
													</div>
												) : (
													<>
														{message.type === 'personal' ? (
															<div
																id={message.messageUuid}
																className={
																	message.messageSenderUserUuid !==
																	splitUserId(userState.currUser.sub)
																		? 'messageItem'
																		: 'messageItemUser'
																}>
																<div className='timestampAndNameCont'>
																	{message.messageSenderUserUuid !==
																	splitUserId(userState.currUser.sub) ? (
																		<p className='messageUserName'>
																			{message.messageSenderName ? (
																				<>{message.messageSenderName}</>
																			) : (
																				<>
																					{message.messageSenderUserUuid ===
																					message.buyerTeamUuid
																						? message.buyerUserRef
																						: message.sellerUserRef}
																				</>
																			)}
																		</p>
																	) : (
																		<p className='messageUserName'>You</p>
																	)}
																	<p className='chatTimestamp'>
																		{timestampConverter(
																			message.sentDateTime,
																			'hh:mm',
																			'Europe/London'
																		)}
																	</p>
																</div>
																<p className='messageBody'>{message.messageText}</p>
															</div>
														) : (
															<div
																id={message.messageUuid}
																className={
																	message.messageSenderTeamUuid !==
																	teamState.currentTeam.teamUuid
																		? 'messageItem'
																		: 'messageItemUser'
																}>
																<div className='timestampAndNameCont'>
																	<p className='messageUserName'>
																		{message.messageSenderName ? (
																			<>{message.messageSenderName}</>
																		) : (
																			<>
																				{message.messageSenderTeamUuid ===
																				message.buyerTeamUuid
																					? message.buyerUserRef
																					: message.sellerUserRef}
																			</>
																		)}
																	</p>

																	<p className='chatTimestamp'>
																		{timestampConverter(
																			message.sentDateTime,
																			'hh:mm',
																			'Europe/London'
																		)}
																	</p>
																</div>
																<p className='messageBody'>{message.messageText}</p>
															</div>
														)}
													</>
												)}
											</React.Fragment>
										);
									})}
									{room?.usersWriting?.map((user, key) => (
										<div key={user.userUuid} className='messageItem'>
											<div className='timestampAndNameCont'>
												{message.messageSenderTeamUuid !== teamState.currentTeam.teamUuid && (
													<p className='messageUserName'>{user.name}</p>
												)}
												<p className='chatTimestamp'>
													{timestampConverter(message.sentDateTime, 'hh:mm', 'Europe/London')}
												</p>
											</div>
											<ChatWritingAnimation />
										</div>
									))}
									<div style={{ visibility: 'hidden' }} ref={(e) => (divEnd.current = e)} />
								</div>
							</>
						) : (
							<>
								<Loading type='circle' />
							</>
						)}
						<form className='messageForm' autoComplete='off'>
							<input
								placeholder={`Message${
									room?.otherParty?.teamInfo?.teamName
										? ` ${room?.otherParty?.teamInfo?.teamName}`
										: ''
								}`}
								onChange={() => {
									handleChange();
								}}
								ref={message}
								name='message'
								className='chatInput'
							/>
							<div className='marginLeft-auto'>
								<Button
									onClick={() => handleNewMessage()}
									type='submit'
									disabled={!inputFilled}
									maxWidth={40}
									minWidth={40}
									iconLeft={<SendIcon />}
								/>
							</div>
						</form>
					</>
				</div>
			</>
		);
	}
);

export default ChatBox;
