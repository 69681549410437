import React from 'react';
import { ChatIcon, MessageChatSquareGrayIcon } from '../../../components/SVGIcons/SVGIcons';

export default function WrapperHeader({}) {
	return (
		<div className='chatWrapperHeader'>
			<div className='leftRow'>
				<MessageChatSquareGrayIcon iconClass='messagingPageIcon' />
				<h1>Messages</h1>
			</div>
		</div>
	);
}
